import { Component, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-steper-solicitudes',
  templateUrl: './steper-solicitudes.component.html',
  styleUrls: ['./steper-solicitudes.component.css']
})
export class SteperSolicitudesComponent implements OnInit {

  @Input() stepActivo:string;
  @Input() decisionSolicitud:string="Aprobado-aceptado";

  constructor() { }

  ngOnInit(): void {
    this.cambiarEstadoSteepes(this.stepActivo);
  }

  async cambiarEstadoSteepes(nomEstado) {
    switch (nomEstado) {
      case "PASO_1":
        await this.delay(500);
        $("#paso1").addClass("active");
        break;
      case "PASO_2":
        await this.delay(500);
        $("#paso1").addClass("active");
        $("#paso2").addClass("active");
        $("#lineaPaso1Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso2Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      case "PASO_3":
        await this.delay(500);
        $("#paso1").addClass("active");
        $("#paso2").addClass("active");
        $("#paso3").addClass("active");
        $("#lineaPaso1Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso2Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso2Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso3Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      case "PASO_4":
        await this.delay(500);
        $("#paso1").addClass("active");
        $("#paso2").addClass("active");
        $("#paso3").addClass("active");
        $("#paso4").addClass("active");
        $("#lineaPaso1Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso2Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso2Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso3Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso3Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso4Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      case "PASO_5":
        await this.delay(500);
        $("#paso1").addClass("active");
        $("#paso2").addClass("active");
        $("#paso3").addClass("active");
        $("#paso4").addClass("active");
        $("#paso5").addClass("active");
        $("#lineaPaso1Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso2Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso2Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso3Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso3Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso4Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaPaso4Der").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaPaso5Izq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      default:
        $("#stepInfoPersonal").addClass("active");
        break;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
