import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { RequestAuthAPI } from '../model/Request';
import { ResponseAuthAPI } from '../model/Response';
@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {

  public url = environment.URL_SERVICE+"APIAuth";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json;charset=UTF-8"
    }
  );

  constructor(private httpClient: HttpClient) { }

  authAPI() {
    let path = this.url + "/";
    path +="?sUser=" + "MTIzNDU2Nzg5&"+"sPass="+"MTIzNDU2Nzg5";
    // path = { sUser: btoa("123456789"), sPass: btoa("123456789") }
    return this.httpClient.post<ResponseAuthAPI>(path,{ headers: this.headers });
  }
}
