<ngx-spinner name="sp" [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>
<nav id="navLg" class="navbar navbarCustom" *ngIf="tipoPerfil=='1'">
  <div class="p-grid w-100">
    <div class="p-col">
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
    <div class="p-col colMenu">
      <i class="fa fa-home iconoNav" aria-hidden="true"></i>
      <p class="txtNav" (click)="navegarRuta('/empleado/home')">Inicio</p>
    </div>
    <!-- <div class="p-col colMenu">
      <p class="txtNav"  (click)="navegarRuta('/empleado/listaSolicitudesCredito')">Solicitudes</p>
    </div> -->
    <div class="p-col colMenu" (click)="navegarRuta('/empleado/cambiarContraseniaAsoc')">
      <p class="txtNav">Contraseñas</p>
    </div>
    <div class="p-col colMenu" (click)="navegarRuta('/empleado/usuarios')">
      <p class="txtNav" >Administración</p>
    </div>
    <div class="p-col colMenu" (click)="navegarRuta('/empleado/parametros')">
      <p class="txtNav" >Parámetros</p>
    </div>
    <div class="p-col colMenu">
      <!-- <i class="fa fa-bell iconNotifi" aria-hidden="true"></i> -->
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/empleado/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
      <!-- <i class="fa fa-user-circle iconoUser" aria-hidden="true" ></i> -->
    </div>
  </div>
</nav>
<nav id="navSM" class="navbar" *ngIf="tipoPerfil=='1'">
  <div class="p-grid">
    <div class="p-col" (click)="abrirMenu()">
      <i class="fa fa-list iconMenuSm" aria-hidden="true"></i>
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
  </div>

  <p-sidebar [(visible)]="menusm" position="left" [baseZIndex]="10000">
    <p class="txtTituloMenu">Escoja una opción</p>
    <div class="itemMenu" (click)="navegarRuta('/empleado/home')">
      <p class="txtItemMenu">Inicio</p>
    </div>
    <div class="itemMenu">
      <p class="txtItemMenu" (click)="navegarRuta('/empleado/listaSolicitudesCredito')">Solicitudes</p>
    </div>
    <div class="itemMenu" (click)="navegarRuta('/empleado/usuarios')">
      <p class="txtItemMenu">Administración</p>
    </div>
    <div class="itemMenu" (click)="navegarRuta('/empleado/parametros')">
      <p class="txtItemMenu">Parámetros</p>
    </div>
    <div class="itemMenu">
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/empleado/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
    </div>
  </p-sidebar>

</nav>

<nav id="navLg" class="navbar navbarCustom" *ngIf="tipoPerfil=='2'">
  <div class="p-grid w-100">
    <div class="p-col">
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
    <div class="p-col colMenu">
      <i class="fa fa-home iconoNav" aria-hidden="true"></i>
      <p class="txtNav" (click)="navegarRuta('/gestorCredito/home')">Inicio</p>
    </div>
    <div class="p-col colMenu">
      <p class="txtNav"  (click)="navegarRuta('/gestorCredito/listaSolicitudesCredito')">Solicitudes</p>
    </div>
    <div class="p-col colMenu">
      <!-- <i class="fa fa-bell iconNotifi" aria-hidden="true"></i> -->
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/gestorCredito/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
      <!-- <i class="fa fa-user-circle iconoUser" aria-hidden="true" ></i> -->
    </div>
  </div>
</nav>
<nav id="navSM" class="navbar" *ngIf="tipoPerfil=='2'">
  <div class="p-grid">
    <div class="p-col" (click)="abrirMenu()">
      <i class="fa fa-list iconMenuSm" aria-hidden="true"></i>
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
  </div>

  <p-sidebar [(visible)]="menusm" position="left" [baseZIndex]="10000">
    <p class="txtTituloMenu">Escoja una opción</p>
    <div class="itemMenu" (click)="navegarRuta('/gestorCredito/home')">
      <p class="txtItemMenu">Inicio</p>
    </div>
    <div class="itemMenu">
      <p class="txtItemMenu" (click)="navegarRuta('/gestorCredito/listaSolicitudesCredito')">Solicitudes</p>
    </div>
    <div class="itemMenu">
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/gestorCredito/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
    </div>
  </p-sidebar>

</nav>

<nav id="navLg" class="navbar navbarCustom" *ngIf="tipoPerfil=='3'">
  <div class="p-grid w-100">
    <div class="p-col">
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
    <div class="p-col colMenu">
      <i class="fa fa-home iconoNav" aria-hidden="true"></i>
      <p class="txtNav" (click)="navegarRuta('/analistaRiesgo/home')">Inicio</p>
    </div>
    <div class="p-col colMenu">
      <p class="txtNav"  (click)="navegarRuta('/analistaRiesgo/listaSolicitudesCredito')">Solicitudes</p>
    </div>
    <div class="p-col colMenu">
      <!-- <i class="fa fa-bell iconNotifi" aria-hidden="true"></i> -->
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/analistaRiesgo/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
      <!-- <i class="fa fa-user-circle iconoUser" aria-hidden="true" ></i> -->
    </div>
  </div>
</nav>
<nav id="navSM" class="navbar" *ngIf="tipoPerfil=='3'">
  <div class="p-grid">
    <div class="p-col" (click)="abrirMenu()">
      <i class="fa fa-list iconMenuSm" aria-hidden="true"></i>
      <img src="./assets/Imagenes/logoFinmeridian.png" class="imgAppBar">
    </div>
  </div>

  <p-sidebar [(visible)]="menusm" position="left" [baseZIndex]="10000">
    <p class="txtTituloMenu">Escoja una opción</p>
    <div class="itemMenu" (click)="navegarRuta('/analistaRiesgo/home')">
      <p class="txtItemMenu">Inicio</p>
    </div>
    <div class="itemMenu">
      <p class="txtItemMenu" (click)="navegarRuta('/analistaRiesgo/listaSolicitudesCredito')">Solicitudes</p>
    </div>
    <div class="itemMenu">
      <table>
        <tr>
          <a class="txtNav" (click)="navegarRuta('/analistaRiesgo/home')">{{nomUsuario}}</a>
        </tr>
        <tr>
          <a class="cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
        </tr>
      </table>
    </div>
  </p-sidebar>

</nav>
