import { DetalleMonto, EstadoPTP } from './DTO/clasesDTO';

export class RequestAuthAPI {
  sUser: string;
  sPass: string;
}

export class RequestLogin {
  tipoDocumento: number;
  tipoPerfil: number;
  numDocumento: string;
  password: string;
  sNavegador: string;
  iTipoCanal: number;
}


export class RequestSolCredito {
  codSolicitud: number;
  codPersona: number;
  codCredito: number;
  numPoliza: string;
  montoSolicitud: string;
  plazo: string;
  valorCuota: string;
  codPeriodo: string;
  codUsuarioCreador: number;
  numPromo:number;
  lineaCred:number;
}

export class ResquestCifin{
  numIdentificacion: string;
  tipoIdentificacion: string;
}

export class RequestInfoCtaBanco {
  codInfoCuenta: number;
  codSolicitud: number;
  nombreTitular: string;
  codTipoCuenta: number;
  numeroCuenta: string;
  banco: string;
  codUsuarioCreador: number;
  sTitular: string;
  sTipoIdent: number;
  sNumDoc: string;
  cEmailCod: string;
  cNumCelular: number;
  cNumTelefono: number;
}

export class RequestDocSolCredito {
  iTipoReqSolCred: number;
  iCodUsuario: number;
  iCodSolCredito: number;
  sObservaciones: string;
  sRutaFTP: string;
  sNombreDoc: string;
}


export class RequestSimulador {
  pwsMonto: string;
  pwsNroCuotas: string;
  pwsFechaSol: string;
  pwsValRecog: string;
  pwsCodCredi: string;
  pwsFormPlazo: string;
  pwsIdentific: string;
  pwsFormPeriodic: string;
}

export class RequestClaveNueva {
  iCodUsuario: number;
  xClaveAnterior: string;
  xClaveNueva: string;
  xClaveNuevaConfirma: string;
}

export class RequestOlvidoClave {
  iCodUsuario: number;
  xClaveAnterior: string;
  xClaveNueva: string;
  xClaveNuevaConfirma: string;
  xTipoIdent: number;
  xIdent: string;
}

export class RequestPlaceToPay {
  xCodPersona:number;
  xiTipoIdent:number;
  xiCredito:number;
  cIdent:string;
  xDireccion:string;
  xPais:string;
  xDepartamento:string;
  xCiudad:string;
  xCodigoPostal:string;
  xIp:string;
  xNavegador:string;
  listaProductos:DetalleMonto[];
}

export class RequestToken{
  xiCodSolicitud:string;
  token:string;
  xiCodUsuario:string;
}

export class RequestEstadoTrans{
  status:EstadoPTP;
  requestId:string;
  reference:string;
  signature:string;
  authorization:string;
}
