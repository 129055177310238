<div class="md-stepper-horizontal">
  <div class="md-step" id="paso1">
    <div class="md-step-circle" pTooltip="Tipo de crédito" tooltipPosition="bottom">1</div>
    <div class="md-step-bar-right" id="lineaPaso1Der"></div>
  </div>
  <div class="md-step" id="paso2">
    <div class="md-step-circle" pTooltip="Información de la cuenta" tooltipPosition="bottom">2</div>
    <div class="md-step-bar-left" id="lineaPaso2Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso2Der"></div>
  </div>
  <!-- <div class="md-step" id="paso3">
    <div class="md-step-circle" pTooltip="Documentos de la solicitud" tooltipPosition="bottom">3</div>
    <div class="md-step-bar-left" id="lineaPaso3Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso3Der"></div>
  </div> -->
  <!-- <div class="md-step" id="paso4">
    <div class="md-step-circle" pTooltip="Autorizaciones" tooltipPosition="bottom">3</div>
    <div class="md-step-bar-left" id="lineaPaso4Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso4Der"></div>
  </div> -->
  <div class="md-step" id="paso5" tooltipPosition="bottom">
    <div class="md-step-circle" pTooltip="Autorizaciones">3</div>
    <div class="md-step-bar-left" id="lineaPaso5Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso5Der"></div>
  </div>
</div>
