// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // URL_SERVICE:'http://localhost:8080/WSRestApiFinmeridian/APIRest/', //  Pruebas Local
  URL_SERVICE: 'https://tswlofinmeridianbe.ebsproductodigital.com/WSRestApiFinmeridiants/APIRest/', //Pruebas


  URL_FTP: 'http://appswebpre.ebscreditodigital.com/FTP_FINMERIDIAN/',
  USUARIO_BMI: 'finmeridian',
  CLAVE_BMI: '*f1n4Nc13r4%'
};

// ng build --output-hashing=all --prod --base-href /Finmeridian/
// ng build --output-hashing=all --prod --base-href /FinmeridianTest/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
