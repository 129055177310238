<div class="md-stepper-horizontal">
  <div class="md-step" id="paso1">
    <div class="md-step-circle"><i class="fas fa-edit"></i></div>
    <div class="md-step-title">
      <span class="stepCorrecto">Diligenciado</span>
    </div>
    <div class="md-step-bar-right" id="lineaPaso1Der"></div>
  </div>
  <div class="md-step" id="paso2">
    <div class="md-step-circle"><i class="fas fa-file-alt"></i></div>
    <div class="md-step-title">
      <span class="stepCorrecto">Radicado</span>
    </div><div class="md-step-bar-left" id="lineaPaso2Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso2Der"></div>
  </div>
  <div class="md-step" id="paso3">
    <div class="md-step-circle"><i class="fas fa-search-plus"></i></div>
    <div class="md-step-title">
      <span class="stepCorrecto">En estudio</span>
    </div><div class="md-step-bar-left" id="lineaPaso3Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso3Der"></div>
  </div>
  <div class="md-step" id="paso4">
    <div class="md-step-circle"><i class="fas fa-list-alt"></i></div>
    <div class="md-step-title">
      <span class="stepCorrecto">{{decisionSolicitud}}</span>
    </div>
    <div class="md-step-bar-left" id="lineaPaso4Izq"></div>
    <div class="md-step-bar-right" id="lineaPaso4Der"></div>
  </div>
</div>
