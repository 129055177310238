import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cliente',
    pathMatch: 'full'
  },
  {
    path:'cliente',
    loadChildren: () => import('./aplicativo/cliente/cliente.module').then( m => m.ClienteModule)
  },
  {
    path:'empleado',
    loadChildren: () => import('./aplicativo/empleado/empleado.module').then( m => m.EmpleadoModule)
  },
  {
    path:'pagare',
    loadChildren: () => import('./aplicativo/pagare/pagare.module').then( m => m.PagareModule)
  },
  {
    path:'gestorCredito',
    loadChildren: () => import('./aplicativo/gestor-credito/gestor-credito.module').then( m => m.GestorCreditoModule)
  },
  {
    path:'analistaRiesgo',
    loadChildren: () => import('./aplicativo/analista-riesgos/analista-riesgos.module').then( m => m.AnalistaRiesgoModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'cliente'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
