import { Router } from '@angular/router';
import { MessageService,ConfirmationService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogAppComponent } from './../aplicativo/pages/dialog-app/dialog-app.component';
import { InfoCuentaDTO, SolicitudCreditoDTO } from './DTO/clasesDTO';
import { RequestInfoCtaBanco, RequestSolCredito } from './Request';
export class General {

  constructor(
    public dialogService: DialogService,
    private ruta: Router,
    private messageService: MessageService) { }


  abrirDialog(tipoAlerta, titulo, mensaje, redireccion) {
    let ref: DynamicDialogRef;
    ref = this.dialogService.open(DialogAppComponent, {
      data: {
        titulo: titulo,
        tipoAlerta: tipoAlerta,
        mensaje: mensaje
      },
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000
    });

    ref.onClose.subscribe((data: any) => {
      if (redireccion != null) {
        this.ruta.navigate([redireccion])
      }
    });
  }

  enviarMensaje(tipoMensaje, titulo, mensaje) {
    this.messageService.add({
      severity: tipoMensaje,
      summary: titulo,
      detail: mensaje
    })
  }

  redirigirRuta(tipoRuta, ruta, indicativo) {
    switch (tipoRuta) {
      case 'sencilla':
        this.ruta.navigate([ruta]);
        break;
      case 'indicador':
        this.ruta.navigate([ruta,indicativo]);
        break;
    }
  }

  validarFormatoMoneda(numFormato) {
    numFormato = numFormato != null && numFormato != undefined ? numFormato.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",") : '';
    return numFormato;
  }

  validarNumeroNegativo(numValidar) {
    numValidar = numValidar != null && numValidar != undefined && numValidar < 0 ? numValidar * (-1) : numValidar;
    return numValidar;
  }

  quitarFormatoMoneda(numFormato) {
    numFormato = numFormato != null && numFormato != undefined ? numFormato.toString().replace(/,/g, '') : '';
    return numFormato;
  }

  responseToRequestSolCredito(responseSolCredito: SolicitudCreditoDTO) {
    let requestRta = new RequestSolCredito();
    requestRta.codSolicitud = responseSolCredito.ICodigo != null ? responseSolCredito.ICodigo : '';
    requestRta.codPersona = responseSolCredito.IPersona != null ? responseSolCredito.IPersona.ICodigo : null;
    requestRta.codCredito = responseSolCredito.ICodCredito != null ? responseSolCredito.ICodCredito : '';
    requestRta.numPoliza = responseSolCredito.INumPoliza != null ? responseSolCredito.INumPoliza : '';
    requestRta.montoSolicitud = responseSolCredito.NMontoSolicitud != null ? responseSolCredito.NMontoSolicitud : '';
    requestRta.plazo = responseSolCredito.plazo != null ? responseSolCredito.plazo : '';
    requestRta.valorCuota = responseSolCredito.NValorCuota != null ? responseSolCredito.NValorCuota : '';
    requestRta.codPeriodo = responseSolCredito.ICodPeriodo != null ? responseSolCredito.ICodPeriodo : '';
    requestRta.codUsuarioCreador = responseSolCredito.IUsuarioCreador != null ? responseSolCredito.IUsuarioCreador.ICodigo : null;
    return requestRta;
  }

  responseToRequestInfoCtaBanco(responseInfoCta: InfoCuentaDTO) {
    let requestRta = new RequestInfoCtaBanco();
    requestRta.codInfoCuenta = responseInfoCta.ICodigo!=null ? responseInfoCta.ICodigo:0;
    requestRta.codSolicitud = responseInfoCta.ISolicitudCredito!=null ? responseInfoCta.ISolicitudCredito.ICodigo:null;
    requestRta.nombreTitular = responseInfoCta.CNombresTitular!=null ? responseInfoCta.CNombresTitular:'';
    requestRta.codTipoCuenta = responseInfoCta.ITipoCuenta!=null ? responseInfoCta.ITipoCuenta:0;
    requestRta.numeroCuenta = responseInfoCta.CNumeroCuenta!=null ? responseInfoCta.CNumeroCuenta:'';
    requestRta.banco = responseInfoCta.CBanco!=null ? responseInfoCta.CBanco:'';
    requestRta.codUsuarioCreador = responseInfoCta.IUsuarioModificador!=null ? responseInfoCta.IUsuarioModificador.ICodigo:null;
    requestRta.sTitular = responseInfoCta.CTitular!=null ? responseInfoCta.CTitular:'';
    requestRta.sTipoIdent = responseInfoCta.ITipoIdent!=null ? responseInfoCta.ITipoIdent:0;
    requestRta.sNumDoc = responseInfoCta.CIdentificacion!=null ? responseInfoCta.CIdentificacion:'';
    return requestRta;
  }
}
