import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { RequestLogin } from './../model/Request';
import { ResponseAuthAPI } from '../model/Response';
import { UtilService } from './util.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url = environment.URL_SERVICE + "Login";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );

  constructor(private httpClient: HttpClient,private utilSer:UtilService) { }


  login(request:RequestLogin,sAuthToken: string) {
    let path=this.url+"/LoginPersona";
    let requestNuevo = new RequestLogin();
    requestNuevo.sNavegador=this.utilSer.obtenerNavegador();
    requestNuevo.numDocumento = btoa(request.numDocumento);
    requestNuevo.password = btoa(request.password);
    requestNuevo.tipoDocumento = request.tipoDocumento;
    requestNuevo.iTipoCanal=1;
    requestNuevo.tipoPerfil = request.tipoPerfil;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseAuthAPI>(path, JSON.stringify(requestNuevo), { headers: this.headers });
  }

  loginEmpleado(request:RequestLogin,sAuthToken: string) {
    let path = this.url + '/LoginPersona';
    let requestNuevo = new RequestLogin();
    requestNuevo.tipoDocumento = 1;
    requestNuevo.tipoPerfil = request.tipoPerfil;
    requestNuevo.numDocumento = btoa(request.numDocumento);
    requestNuevo.password = btoa(request.password);
    requestNuevo.sNavegador = this.utilSer.obtenerNavegador();
    requestNuevo.iTipoCanal=1;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseAuthAPI>(path, JSON.stringify(requestNuevo) ,{headers: this.headers});
  }

  cerrarSesionEmpleado(xiUsuario: number,sAuthToken: string){
    let path = this.url + '/CerrarSession';
    path += `?iUsuario=${xiUsuario}`;
    path += `&idTipoCanal=${1}`;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post(path, { headers: this.headers });
  }

  validarOlvidoClave(xData: string) {
    let path = this.url + "/validaOlvidoClave";
    path += "?xsData=" + xData;
    return this.httpClient.post(path, { headers: this.headers });
  }

  generarNuevaClave(xData: string,sAuthToken: string) {
    let path = this.url + "/generarNuevaClaveWeb";
    path += "?xsData=" + xData;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post(path, { headers: this.headers });
  }


  getIp() {
    return this.httpClient.get("https://api.ipify.org/?format=json");
  }



}
