import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBarComponent } from './aplicativo/pages/app-bar/app-bar.component';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { DialogAppComponent } from './aplicativo/pages/dialog-app/dialog-app.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import {FieldsetModule} from 'primeng/fieldset';
import { AppBarClienteComponent } from './aplicativo/pages/app-bar-cliente/app-bar-cliente.component';
import { SteperSolCreditoComponent } from './aplicativo/pages/steper-sol-credito/steper-sol-credito.component';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import { SteperSolicitudesComponent } from './aplicativo/pages/steper-solicitudes/steper-solicitudes.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { BloquearCopiarPegarDirective } from './directivas/bloquear-copiar-pegar.directive';

@NgModule({
  declarations: [
    AppBarComponent,
    DialogAppComponent,
    AppBarClienteComponent,
    SteperSolCreditoComponent,
    SteperSolicitudesComponent,
    BloquearCopiarPegarDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    SidebarModule,
    DynamicDialogModule,
    FieldsetModule,
    TooltipModule,
    RadioButtonModule,
    InputSwitchModule,
    NgxSpinnerModule,
    ToastModule,
    InputNumberModule,
    InputTextModule,
    DialogModule,
    ConfirmDialogModule
  ],
  exports: [
    AppBarComponent,
    AppBarClienteComponent,
    DialogAppComponent,
    SteperSolCreditoComponent,
    SteperSolicitudesComponent,
    FormsModule,
    TableModule,
    SidebarModule,
    DynamicDialogModule,
    FieldsetModule,
    TooltipModule,
    RadioButtonModule,
    InputSwitchModule,
    NgxSpinnerModule,
    ToastModule,
    InputNumberModule,
    InputTextModule,
    DialogModule,
    ConfirmDialogModule
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService
  ],
  entryComponents: [
    DialogAppComponent
  ]
})
export class CompartidoModule { }
