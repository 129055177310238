import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBloquearCopiarPegar]'
})
export class BloquearCopiarPegarDirective {

  constructor(el: ElementRef, renderer: Renderer2) {
    var events = 'cut copy paste';
      events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
        })
      );
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    console.log("pegar");
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    console.log("copia");
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    console.log("cortar");
    e.preventDefault();
  }
}
