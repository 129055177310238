import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/services/autenticacion.service';
import { LoginService } from '../../../services/login.service';
import { CargarTodosUsuariosService } from 'src/app/services/cargar-todos-usuarios.service';
import { MessageService } from 'primeng/api';
import { General } from 'src/app/model/General';
import { TokenService } from './../../../services/token.service';

@Component({
  selector: 'app-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.css']
})
export class AppBarComponent extends General implements OnInit {

  @Input() tipoPerfil:string="1";
  public menusm:boolean=false;
  public idLogueado: number;

  //Suscripciones
  public subApiCerrarSesion: Subscription;
  public subCerrarSesion: Subscription;
  public nomUsuario: string;
  constructor(
    private loginService:LoginService,
    private spinner: NgxSpinnerService,
    private authApi: AutenticacionService,
    private messageServiceE: MessageService,
    private tokenServi: TokenService,
    private rutaE: Router) {
      super(null, rutaE, messageServiceE);
     }

  ngOnInit(): void {
    if (this.tokenServi.getCodUserEmpleado() !== 0) {
      this.nomUsuario = this.tokenServi.getNombresEmpleado();
    }
    else if(this.tokenServi.getCodUserGestor() !== 0) {
      this.nomUsuario = this.tokenServi.getNombresGestor();
    }
    else if(this.tokenServi.getCodUserAnalistaRiesgo() !== 0) {
      this.nomUsuario = this.tokenServi.getNombresAnalistaRiesgo();
    }
  }

  navegarRuta(rutaP){
    this.rutaE.navigate([rutaP]);
  }

  abrirMenu(){
    this.menusm = true;
  }

  cerrarSesion(){
    this.idLogueado = this.tokenServi.getCodPersonaEmpleado();
    this.spinner.show("sp");
    this.subApiCerrarSesion = this.authApi.authAPI().subscribe(
      (data: any) => {
        if (data != null && data != undefined) {
          if (data.bRta) {
            this.subCerrarSesion = this.loginService.cerrarSesionEmpleado(this.idLogueado, data.sToken).subscribe(
              (res: any) => {
                if (res.bRta) {
                  this.spinner.hide("sp");
                  this.navegarRuta('empleado/login');
                } else {
                  this.spinner.hide("sp");
                  this.enviarMensaje('warn', 'Alerta', res.sMsj);
                }
              },
              (error) => {
                this.spinner.hide("sp");
            this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
              }
            )
          } else {
            this.spinner.hide("sp");
            this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
          }
        } else {
          this.spinner.hide("sp");
          this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
        }
      }, (error) => {
        console.log(error);
        this.spinner.hide("sp");
        this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error de conexión, intente de nuevo');
      }
    )
  }

}
