import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-app',
  templateUrl: './dialog-app.component.html',
  styleUrls: ['./dialog-app.component.css']
})
export class DialogAppComponent implements OnInit {

  public titulo:string;
  public mensaje:string;
  public tipoAlerta:string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.titulo = this.config.data.titulo;
    this.mensaje = this.config.data.mensaje;
    this.tipoAlerta = this.config.data.tipoAlerta;
  }

  cerrarModal(){
    this.ref.close();
  }

}
