import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { General } from 'src/app/model/General';
import { AutenticacionService } from 'src/app/services/autenticacion.service';
import { LoginService } from 'src/app/services/login.service';
import { TokenService } from './../../../services/token.service';

@Component({
  selector: 'app-app-bar-cliente',
  templateUrl: './app-bar-cliente.component.html',
  styleUrls: ['./app-bar-cliente.component.css']
})
export class AppBarClienteComponent extends General implements OnInit {

  public menusm: boolean = false;
  public nomCliente: string = "";
  public idLogueado: number;
  //Suscripciones
  public subApiCerrarSesion: Subscription;
  public subCerrarSesion: Subscription;

  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private authApi: AutenticacionService,
    private messageServiceE: MessageService,
    private tokenServi: TokenService,
    private rutaE: Router) {
    super(null, rutaE, messageServiceE);
  }S

  ngOnInit(): void {
    let nombres = this.tokenServi.getNombres();
    this.nomCliente = nombres;
  }

  navegarRuta(rutaP) {
    this.redirigirRuta('sencilla', rutaP, null);
    // this.ruta.navigate([rutaP]);
  }

  abrirMenu() {
    this.menusm = true;
  }

  cerrarSesion() {
    this.idLogueado = this.tokenServi.getCodPersonaEmpleado();
    this.spinner.show("sp");
    this.subApiCerrarSesion = this.authApi.authAPI().subscribe(
      (data: any) => {
        if (data != null && data != undefined) {
          if (data.bRta) {
            this.subCerrarSesion = this.loginService.cerrarSesionEmpleado(this.idLogueado, data.sToken).subscribe(
              (res: any) => {
                if (res.bRta) {
                  this.spinner.hide("sp");
                  this.navegarRuta('cliente/login');
                } else {
                  this.spinner.hide("sp");
                  this.enviarMensaje('warn', 'Alerta', res.sMsj);
                }
              },
              (error) => {
                this.spinner.hide("sp");
                this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
              }
            )
          } else {
            this.spinner.hide("sp");
            this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
          }
        } else {
          this.spinner.hide("sp");
          this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error al intentar cerrar la sesión, intente de nuevo');
        }
      }, (error) => {
        console.log(error);
        this.spinner.hide("sp");
        this.enviarMensaje('warn', 'Alerta', 'Ha ocurrido un error de conexión, intente de nuevo');
      }
    )
  }

}
